import React from 'react';


import AppRouter from "./components/RouterComponent";
import './App.css';

function App() {
  return (
    <div>
      <AppRouter/>
    </div>
  );


}

export default App;
