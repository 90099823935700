import React from "react";


import BookListComponent from "./booksearch";


export default function IndexPage() {

  return (<div>
            <BookListComponent />
            </div>);
}
